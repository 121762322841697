//@flow

import {
    Autocomplete,
    Input,
    Thumbnail,
} from "@brutextiles/web-component-library";
import React, { type Node } from "react";
import { Badge } from "reactstrap";

import defaultThumbnail from "../../images/default_render_thumbnail.jpg";
import style from "./style.module.scss";

type Props = {
    frames: any[],
    descriptionOptions: string[],
    fileTemplates: string[],
    onChangeDescription: (any, number) => void,
    onChangeFileTemplatePath: (any, number) => void,
    onAddDescription: string => void,
    errors?: { [string]: string },
    readOnly?: boolean,
};

const FrameDetailsBody = ({
    frames,
    descriptionOptions,
    fileTemplates,
    onChangeDescription: handleChangeDescription,
    onChangeFileTemplatePath: handleChangeFileTemplatePath,
    onAddDescription: handleAddDescription,
    errors,
    readOnly = false,
}: Props): Node => (
    <tbody>
        {frames.map((frame, index) => (
            <tr key={frame.sequence}>
                <td className={style.sequenceColumn}>
                    <Badge className={style.sequence} color={"light"}>
                        {frame.sequence}
                    </Badge>
                </td>
                <td className={style.thumbnailColumn}>
                    <div className="mr-4">
                        <Thumbnail
                            size="md"
                            imageUrl={frame.thumbnailUrl || defaultThumbnail}
                        />
                    </div>
                </td>
                <td className={style.nameColumn}>
                    <div className={style.badge}>{frame.cameraName}</div>
                </td>
                <td className={style.descriptionColumn}>
                    {readOnly ? (
                        <Input
                            type={"text"}
                            value={frame.label}
                            disabled={true}
                        />
                    ) : (
                        <Autocomplete
                            value={frame.label}
                            key={frame.label}
                            allowCreate
                            createOnLeave
                            onChange={selectedOption =>
                                handleChangeDescription(selectedOption, index)
                            }
                            items={descriptionOptions.map(option => ({
                                value: option,
                                label: option,
                            }))}
                            onAddItem={handleAddDescription}
                        />
                    )}

                    {errors && errors[`frame-${index}-description`] && (
                        <div className="text-danger mt-1">
                            {errors[`frame-${index}-description`]}
                        </div>
                    )}
                </td>
                <td className={style.sceneRatioColumn}>
                    <div
                        className={style.badge}
                    >{`${frame.resolutionNumerator}/${frame.resolutionDenominator}`}</div>
                </td>
                <td>
                    {readOnly ? (
                        <Input
                            type={"text"}
                            value={frame.compositingTemplateFilePath}
                            disabled={true}
                        />
                    ) : (
                        <Autocomplete
                            value={fileTemplates.find(
                                template =>
                                    template ===
                                    frame.compositingTemplateFilePath,
                            )}
                            key={frame.compositingTemplateFilePath}
                            onChange={selectedOption =>
                                handleChangeFileTemplatePath(
                                    selectedOption,
                                    index,
                                )
                            }
                            items={fileTemplates.map(option => ({
                                value: option,
                                label: option,
                            }))}
                            onAddItem={handleAddDescription}
                        />
                    )}

                    {errors && errors[`frame-${index}-template-file-path`] && (
                        <div className="text-danger mt-1">
                            {errors[`frame-${index}-template-file-path`]}
                        </div>
                    )}
                </td>
            </tr>
        ))}
    </tbody>
);

export default FrameDetailsBody;
