import style from "./material-overview.module.scss";

const States = {
    PREPARING: "Preparing",
    DRAFT: "Draft",
    PUBLISHED: "Published",
    REJECTED: "Rejected",
    APPROVED: "Approved",
    AWAITING_APPROVAL: "Awaiting approval",
};

export default {
    definitions: [
        {
            id: "scanning",
            label: "Scanning",
            isDefault: true,
            columns: [
                {
                    key: "companyId",
                    label: "Company ID",
                    width: "6%",
                },
                {
                    key: "skuId",
                    label: "SKU ID",
                    width: "6%",
                },
                {
                    key: "brand",
                    label: "Brand",
                    width: "6%",
                },
                {
                    key: "collection",
                    label: "Collection",
                    width: "12%",
                },
                {
                    key: "design",
                    label: "Design",
                    width: "12%",
                },
                {
                    key: "colour",
                    label: "Colour",
                    width: "8%",
                },
                {
                    key: "materialType",
                    label: "Type",
                },
                {
                    key: "endUse",
                    label: "End use",
                    width: "10%",
                },
                {
                    key: "modificationTime",
                    label: "Last modified",
                    defaultSortOrder: "desc",
                    width: "8%",
                },
                {
                    key: "version",
                    label: "Version",
                    width: "5%",
                },
                {
                    key: "state",
                    label: "Status",
                    width: "8%",
                },
            ],
        },

        {
            id: "qc",
            label: "QC",
            columns: [
                {
                    key: "companyId",
                    label: "Company ID",
                    width: "6%",
                },
                {
                    key: "brand",
                    label: "Brand",
                    width: "6%",
                },
                {
                    key: "collection",
                    label: "Collection",
                    width: "12%",
                },
                {
                    key: "design",
                    label: "Design",
                    width: "12%",
                },
                {
                    key: "colour",
                    label: "Colour",
                    width: "8%",
                },
                {
                    key: "materialType",
                    label: "Type",
                },
                {
                    key: "endUse",
                    label: "End use",
                    width: "10%",
                },
                {
                    key: "repeatSize",
                    label: "Repeat size",
                },
                {
                    key: "modificationTime",
                    label: "Last modified",
                    defaultSortOrder: "desc",
                    width: "8%",
                },
                {
                    key: "version",
                    label: "Version",
                    width: "5%",
                },
                {
                    key: "state",
                    label: "Status",
                    width: "9%",
                },
            ],
        },
        {
            id: "publishing",
            label: "Publishing",
            columns: [
                {
                    key: "companyId",
                    label: "Company ID",
                    width: "6%",
                },
                {
                    key: "skuId",
                    label: "SKU ID",
                    width: "6%",
                },
                {
                    key: "launch",
                    label: "Launch",
                    width: "6%",
                },
                {
                    key: "brand",
                    label: "Brand",
                },
                {
                    key: "collection",
                    label: "Collection",
                },
                {
                    key: "design",
                    label: "Design",
                },
                {
                    key: "colour",
                    label: "Colour",
                },
                {
                    key: "endUse",
                    label: "End use",
                },
                {
                    key: "repeatSize",
                    label: "Repeat size",
                },
                {
                    key: "modificationTime",
                    label: "Last modified",
                    defaultSortOrder: "desc",
                },
                {
                    key: "version",
                    label: "Version",
                },
                {
                    key: "state",
                    label: "Status",
                },
            ],
        },
        {
            id: "design",
            label: "Design",
            columns: [
                {
                    key: "companyId",
                    label: "Company ID",
                    width: "6%",
                },
                {
                    key: "skuId",
                    label: "SKU ID",
                    width: "6%",
                },
                {
                    key: "designId",
                    label: "Design ID",
                    width: "6%",
                },
                {
                    key: "brand",
                    label: "Brand",
                },
                {
                    key: "collection",
                    label: "Collection",
                },
                {
                    key: "design",
                    label: "Design",
                },
                {
                    key: "colour",
                    label: "Colour",
                },
                {
                    key: "materialType",
                    label: "Type",
                },
                {
                    key: "velvetShader",
                    label: "Velvet Shader",
                },
                {
                    key: "continuity",
                    label: "Continuity",
                },
                {
                    key: "modificationTime",
                    label: "Last modified",
                    defaultSortOrder: "desc",
                },
                {
                    key: "version",
                    label: "Version",
                },
                {
                    key: "state",
                    label: "Status",
                },
            ],
        },
    ],
    rowClassName: () => style.materialOverviewRow,
    headerClassName: style.materialOverviewRow,
    customSearchLabels: {
        materialId: "Material ID",
        skuStatus: "SKU Status",
        velvetShader: "Velvet Shader",
    },
    actionLabel: "Add views",
    searchLabel: "Search SKU ID, Company etc.",
    rowId: "skuId",
    customFilters: [
        { label: "All", value: { field: "state", value: "" } },
        ...Object.keys(States).map(state => ({
            label: States[state],
            value: { field: "state", value: state },
        })),
    ],
    itemsPerPage: 50,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
    searchCategoryLimits: { min: 5, max: 50 },
};
