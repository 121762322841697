//@flow

import { dateFormatter } from "../../../utils/formatter";
import { getStateBadge } from "../../MaterialWorkflowForm/type/material-state.d";
import { type MaterialDataTableElement } from "../types/material-data-table-element.d";

export default (
    results: MaterialDataTableElement[],
): MaterialDataTableElement[] =>
    results.map(result => ({
        ...result,
        modificationTime: dateFormatter(result.modificationTime),
        state: getStateBadge(result.state),
        velvetShader:
            result.velvetShader === "VELVET SHADER ENABLED"
                ? "Enabled"
                : "Disabled",
    }));
