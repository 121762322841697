// @flow

import {
    Button,
    Card,
    DetailsForm,
    FileUpload,
    Heading,
    LoadIndicator,
    Spinner,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Button as Btn, Container } from "reactstrap";

import { getUploadStatus } from "../../api";
import { Comment, ErrorHandling } from "../../components";
import { useErrorHandlingWithForm, useUpdate, useUpload } from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import detailSettings from "../CreateNoiseMask/detail-settings";
import validationSchema from "../CreateNoiseMask/validation-settings";
import detailVelvetSettings from "./velvet-form-settings";

type Props = {
    noiseMaskId: string,
    readOnly?: boolean,
};

const UpdateNoiseMask = ({ noiseMaskId, readOnly }: Props): Node => {
    const [fileValidationInProgress, setFileValidationInProgress] = useState();
    const [{ loading, data, error }, load] = useAxios(
        `/ams-api/noisemask/${noiseMaskId}/latest`,
        {
            useCache: false,
        },
    );

    const [{ updateLoading }, update] = useUpdate({
        overviewUrl: "/noise-masks",
    });

    const [uploadStatus, setUploadStatus] = useState();

    const [
        {
            progress: uploadProgress,
            done: uploadFinished,
            loading: uploadLoading,
            uploadData: uploadNoiseMaskData,
            errorOnUpload,
            fileName,
        },
        uploadFile,
        resetUpload,
    ] = useUpload("/ams-api/noisemask", data?.contentLocation);

    useEffect(() => {
        if (uploadNoiseMaskData?.Location) {
            setDirty(true);
            setFileValidationInProgress(true);

            getUploadStatus(
                "/ams-api/noisemask/upload/status",
                {
                    s3Url: uploadNoiseMaskData.Location,
                },
                setFileValidationInProgress,
                setUploadStatus,
            );
        }
    }, [uploadNoiseMaskData]);

    useEffect(() => {
        if (data) {
            formik.resetForm({ values: { ...data, noiseMaskId } });
        }
    }, [data]);

    useEffect(() => {
        if (uploadStatus?.status === "IN_PROGRESS") {
            setTimeout(
                () =>
                    getUploadStatus(
                        "/ams-api/noisemask/upload/status",
                        {
                            s3Url: uploadNoiseMaskData.Location,
                        },
                        setFileValidationInProgress,
                        setUploadStatus,
                    ),
                3000,
            );
        } else if (uploadStatus) {
            setFileValidationInProgress(false);
        }
    }, [uploadStatus]);

    const { setDirty } = useContext(FormContext);

    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const formik = useFormik({
        initialValues: {
            height: "",
            width: "",
            comment: "",
            noiseAddFactor: "0.2",
        },
        validationSchema: validationSchema(data),
        onSubmit: () => {
            let url = `/ams-api/noisemask/${noiseMaskId}/metadata`;
            const data = {
                comment: formik.values.comment,
                width: Number(formik.values["width"]),
                height: Number(formik.values["height"]),
                noiseAddFactor: Number(formik.values["noiseAddFactor"]),
                s3FileUrl: "",
                noiseMaskType: formik.values["noiseMaskType"],
                noiseMaskName: formik.values["noiseMaskName"],
            };
            if (uploadNoiseMaskData?.Location) {
                data.s3FileUrl = uploadNoiseMaskData.Location;
                url = `/ams-api/noisemask/${noiseMaskId}`;
            }
            update({
                url,
                data,
            });
        },
    });

    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    useErrorHandlingWithForm("update-noise-mask-form", formik.errors);

    const submit = (): void => {
        setShouldValidateOnChange();
        formik.handleSubmit();
    };

    const actionButtons = (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/noise-masks")}
            >
                {readOnly ? "Back" : "Cancel"}
            </Button>
            {!readOnly && (
                <Button
                    onClick={submit}
                    disabled={
                        (!uploadNoiseMaskData?.Location &&
                            (!formik.isValid || !formik.dirty)) ||
                        fileValidationInProgress
                    }
                >
                    {updateLoading || fileValidationInProgress ? (
                        <Spinner size="sm" />
                    ) : (
                        "Update"
                    )}
                </Button>
            )}
        </Fragment>
    );

    return (
        <Container fluid>
            <Heading
                title={
                    readOnly
                        ? `Noise Mask ${data?.companyId}-${data?.designId}`
                        : "Update Noise Mask"
                }
                rightContent={actionButtons}
            />
            <ErrorHandling />
            {!readOnly && (
                <>
                    <Heading title={"Upload Noise Mask"} level={5} />
                    <Card>
                        <FileUpload
                            onAddFile={uploadFile}
                            infoContent="Please select a valid .png file for upload"
                            validExtensions=".png"
                            progress={uploadProgress}
                            uploadFinished={uploadFinished}
                            onReset={resetUpload}
                            isUploading={uploadLoading}
                            error={errorOnUpload}
                            fileName={fileName}
                        />
                    </Card>
                </>
            )}
            <Heading title={"Noise Mask Details"} level={5} />
            <Card>
                {loading ? (
                    <LoadIndicator rows={3} cols={2} />
                ) : error ? (
                    <span className="text-danger">
                        Error while retrieving the data
                        <div>
                            <Btn className="mt-2" onClick={load}>
                                Try loading data again
                            </Btn>
                        </div>
                    </span>
                ) : (
                    <DetailsForm
                        settings={
                            data?.noiseMaskType === "VELVET"
                                ? detailVelvetSettings(readOnly)
                                : detailSettings(readOnly)
                        }
                        data={{ ...formik.initialValues, ...formik.values }}
                        onChange={data =>
                            formik.setFieldValue(
                                data.key,
                                data.value,
                                shouldValidateOnChange,
                            )
                        }
                        errors={formik.errors}
                    />
                )}
            </Card>

            {!readOnly && (
                <Comment
                    comment={formik.values.comment}
                    onChange={value =>
                        formik.setFieldValue(
                            "comment",
                            value,
                            shouldValidateOnChange,
                        )
                    }
                    error={formik.errors.comment}
                />
            )}
        </Container>
    );
};

export default UpdateNoiseMask;
