export default () => {
    return [
        {
            label: "Noise Mask Type",
            key: "noiseMaskType",
            inputType: "select",
            readOnly: true,
            selectOptions: [
                { value: "GENERIC", label: "Generic Noise Mask" },
                { value: "VELVET", label: "Velvet Noise Mask" },
            ],
            width: 12,
        },
    ];
};
