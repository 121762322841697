// @flow

import {
    Button,
    Card,
    DetailsForm,
    FileUpload,
    Heading,
    LoadIndicator,
    Modal,
    Spinner,
    Tabs,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Button as Btn, Container } from "reactstrap";
import * as Yup from "yup";

import { getUploadStatus } from "../../api";
import { Comment, ErrorHandling } from "../../components";
import { useErrorHandlingWithForm, useUpdate, useUpload } from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import {
    adjustmentSettings,
    mainDetailsSettings,
    mapDataForUpdate,
    metadataSettings,
    updateTabs,
} from "../CreateMaterial/detail-settings";
import style from "./update-material.module.scss";

type Props = {
    companyId: string,
    skuId: string,
    readOnly?: boolean,
};

const multiplyDisplacement = (displacement?: number) => {
    return displacement && parseFloat(displacement * 100).toFixed(2);
};

const divideDisplacement = (displacement?: number) => {
    return displacement && parseFloat(displacement / 100);
};

const onChangeDetailsFormEntry = (
    entry,
    formik,
    data,
    velvetNoiseMasksData,
    shouldValidateOnChange,
    setDirty,
) => {
    if (["exposureCorrectionFactor", "noiseAddFactor"].includes(entry.key)) {
        formik.setFieldValue(entry.key, entry.value, shouldValidateOnChange);
        setDirty(true);
    } else if (entry.key === "overrideNoiseAddFactor") {
        formik.setFieldValue(
            "overrideNoiseAddFactor",
            entry.value,
            shouldValidateOnChange,
        );

        if (!entry.value) {
            const noiseMaskVersionId = formik.values["noiseMaskVersionId"];
            if (noiseMaskVersionId) {
                const noiseMask = velvetNoiseMasksData?.velvetNoiseMasks.find(
                    noiseMask => noiseMask.versionId === noiseMaskVersionId,
                );
                formik.setFieldValue(
                    "noiseAddFactor",
                    noiseMask?.noiseAddFactor,
                    shouldValidateOnChange,
                );
            }
        } else if (!formik.values["useVelvetShader"]) {
            formik.setFieldValue(
                "noiseAddFactor",
                formik.values["defaultNoiseAddFactor"],
                shouldValidateOnChange,
            );
        }
    } else if (entry.key === "useVelvetShader") {
        formik.setFieldValue(
            "useVelvetShader",
            entry.value,
            shouldValidateOnChange,
        );
        if (entry.value) {
            const defaultNoiseMask = velvetNoiseMasksData?.velvetNoiseMasks[0];
            formik.setFieldValue(
                "noiseMaskVersionId",
                defaultNoiseMask?.versionId,
                shouldValidateOnChange,
            );
            formik.setFieldValue(
                "noiseAddFactor",
                defaultNoiseMask?.noiseAddFactor,
                shouldValidateOnChange,
            );
        }
        formik.setFieldValue(
            "overrideNoiseAddFactor",
            false,
            shouldValidateOnChange,
        );

        setDirty(true);
    } else if (entry.key === "transparent") {
        formik.setFieldValue(
            entry.key,
            entry.value === "TRANSPARENT",
            shouldValidateOnChange,
        );
        setDirty(true);
    } else if (entry.key === "overrideTransparency") {
        formik.setFieldValue(entry.key, entry.value, shouldValidateOnChange);

        if (!entry.value) {
            formik.setFieldValue(
                "transparent",
                data.defaultTransparency,
                shouldValidateOnChange,
            );
        }

        setDirty(true);
    } else if (entry.key === "overrideDisplacement") {
        formik.setFieldValue(entry.key, entry.value, shouldValidateOnChange);

        if (!entry.value) {
            formik.setFieldValue(
                "displacementMultiplier",
                data?.defaultDisplacement * 100,
                shouldValidateOnChange,
            );
        }

        setDirty(true);
    } else if (entry.key === "displacementMultiplier") {
        formik.setFieldValue(entry.key, entry.value, shouldValidateOnChange);

        setDirty(true);
    } else if (entry.key === "noiseMaskVersionId") {
        if (entry.value) {
            const velvetNoiseMask = velvetNoiseMasksData?.velvetNoiseMasks.find(
                noiseMask => noiseMask.versionId === entry.value,
            );
            formik.setFieldValue(
                "noiseAddFactor",
                velvetNoiseMask?.noiseAddFactor,
                shouldValidateOnChange,
            );
        }
        formik.setFieldValue(entry.key, entry.value, shouldValidateOnChange);
        setDirty(true);
    }
};

const UpdateMaterial = ({
    companyId,
    skuId,
    readOnly = false,
}: Props): Node => {
    const [showModal, setShowModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [detailsFormSettings, setDetailsFormSettings] = useState(
        mainDetailsSettings(),
    );
    const [{ loading, data, error }, load] = useAxios(
        `/ams-api/material/${companyId}/${skuId}/latest`,
        {
            useCache: false,
        },
    );

    const [, archive] = useAxios(
        {
            url: `/ams-api/material/${companyId}/${skuId}/`,
            method: "DELETE",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    const [{ data: velvetNoiseMasksData }] = useAxios(
        `/ams-api/noisemask/velvet`,
        {
            useCache: false,
        },
    );

    const [fileValidationInProgress, setFileValidationInProgress] =
        useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const [uploadStatus, setUploadStatus] = useState();

    useEffect(() => {
        if (uploadStatus?.status === "IN_PROGRESS") {
            setTimeout(
                () =>
                    getUploadStatus(
                        "/ams-api/material/upload/status",
                        {
                            s3Url: uploadData.Location,
                        },
                        setFileValidationInProgress,
                        setUploadStatus,
                    ),
                3000,
            );
        } else if (uploadStatus) {
            if (uploadStatus.status === "VALID") {
                formik.setFieldError("materialFile");
            }
            setFileValidationInProgress(false);
        }
    }, [uploadStatus]);

    const [{ updateLoading }, update] = useUpdate({
        overviewUrl: "/materials",
    });

    const [
        {
            progress: uploadProgress,
            done: uploadFinished,
            loading: uploadLoading,
            uploadData,
            errorOnUpload,
            fileName,
        },
        uploadFile,
        resetUpload,
    ] = useUpload("/ams-api/material", data?.contentLocation);

    const getNoiseAddFactor = (data, velvetNoiseMasksData) => {
        if (!data?.useVelvetShader || data?.overrideNoiseAddFactor) {
            return data?.noiseAddFactor;
        }

        const noiseMask = velvetNoiseMasksData?.velvetNoiseMasks?.find(
            noiseMask => noiseMask.versionId === data.noiseMaskVersionId,
        );
        return noiseMask?.noiseAddFactor;
    };

    const formik = useFormik({
        initialValues: {
            ...data,
            fileUrl: "",
            comment: "",
            displacementMultiplier: multiplyDisplacement(
                data?.displacementMultiplier,
            ),
            exposureCorrectionFactor: data?.exposureCorrectionFactor || 1,
            noiseAddFactor: getNoiseAddFactor(data, velvetNoiseMasksData),
        },
        validationSchema: Yup.object({
            comment: Yup.string().required("Comment field is required"),
            exposureCorrectionFactor: Yup.number().required(
                "Exposure correction factor is required",
            ),
            displacementMultiplier: Yup.number().min(0),
            noiseMaskVersionId: Yup.string().when("useVelvetShader", {
                is: useVelvetShader => useVelvetShader,
                then: Yup.string().required(
                    "Velvet noise mask is a required field for VELVET materials",
                ),
                otherwise: Yup.string(),
            }),
        }),
        isInitialValid: false,
        enableReinitialize: true,
        onSubmit: () => submit(),
    });

    const { setDirty } = useContext(FormContext);

    useErrorHandlingWithForm("update-material-form", formik.errors);

    useEffect(() => {
        if (uploadData?.Location) {
            setFileValidationInProgress(true);
            getUploadStatus(
                "/ams-api/material/upload/status",
                {
                    s3Url: uploadData.Location,
                },
                setFileValidationInProgress,
                setUploadStatus,
            );
            formik.setFieldValue(
                "fileUrl",
                uploadData?.Location,
                shouldValidateOnChange,
            );
            setDirty(true);
        }
    }, [uploadData?.Location]);

    const submit = (): void => {
        if (uploadData?.Location) {
            update({
                url: `/ams-api/material/${companyId}/${skuId}`,
                data: {
                    comment: formik.values.comment,
                    exposureCorrectionFactor: Number(
                        formik.values["exposureCorrectionFactor"],
                    ),
                    s3FileUrl: uploadData?.Location,
                    transparent: formik.values["transparent"],
                    overrideTransparency: Boolean(
                        formik.values["overrideTransparency"],
                    ),
                    overrideDisplacement: Boolean(
                        formik.values["overrideDisplacement"],
                    ),
                    useVelvetShader: Boolean(formik.values["useVelvetShader"]),
                    displacementMultiplier: divideDisplacement(
                        Number(formik.values["displacementMultiplier"]),
                    ),
                    materialType:
                        uploadData?.Location.split(".").pop() === "zip"
                            ? "VIZOO"
                            : "AXF",
                    noiseMaskVersionId: formik.values.noiseMaskVersionId,
                    overrideNoiseAddFactor: Boolean(
                        formik.values["overrideNoiseAddFactor"],
                    ),
                    noiseAddFactor: Number(formik.values["noiseAddFactor"]),
                },
            });
        } else {
            update({
                url: `/ams-api/material/${companyId}/${skuId}/metadata`,
                data: {
                    comment: formik.values.comment,
                    exposureCorrectionFactor: Number(
                        formik.values["exposureCorrectionFactor"],
                    ),
                    overrideNoiseAddFactor: Boolean(
                        formik.values["overrideNoiseAddFactor"],
                    ),
                    overrideDisplacement: Boolean(
                        formik.values["overrideDisplacement"],
                    ),
                    useVelvetShader: Boolean(formik.values["useVelvetShader"]),
                    displacementMultiplier: divideDisplacement(
                        Number(formik.values["displacementMultiplier"]),
                    ),
                    noiseAddFactor: Number(formik.values["noiseAddFactor"]),
                    transparent: formik.values["transparent"],
                    overrideTransparency: Boolean(
                        formik.values["overrideTransparency"],
                    ),
                    noiseMaskVersionId: formik.values.noiseMaskVersionId,
                },
            });
        }
    };

    useEffect(() => {
        if (currentTab === 0) {
            setDetailsFormSettings(mainDetailsSettings());
        }

        if (currentTab === 1) {
            setDetailsFormSettings(metadataSettings());
        }

        if (currentTab === 2) {
            setDetailsFormSettings(
                adjustmentSettings(
                    readOnly,
                    formik.values.overrideNoiseAddFactor,
                    formik.values.overrideTransparency,
                    formik.values.overrideDisplacement,
                    formik.values.noiseMaskVersion,
                    formik.values.useVelvetShader,
                    velvetNoiseMasksData,
                ),
            );
        }
    }, [
        currentTab,
        readOnly,
        formik.values.overrideNoiseAddFactor,
        formik.values.overrideTransparency,
        formik.values.overrideDisplacement,
        formik.values.noiseMaskVersion,
        formik.values.useVelvetShader,
        velvetNoiseMasksData,
    ]);

    const actionButtons = (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/materials")}
            >
                {readOnly ? "Back" : "Cancel"}
            </Button>
            {!readOnly && (
                <Button
                    onClick={() => {
                        setShouldValidateOnChange(true);
                        formik.handleSubmit();
                    }}
                    disabled={!formik.isValid || fileValidationInProgress}
                >
                    {fileValidationInProgress || updateLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        "Update"
                    )}
                </Button>
            )}
        </Fragment>
    );

    return (
        <Container fluid>
            <Heading
                title={readOnly ? "Material " + skuId : "Update Material"}
                rightContent={actionButtons}
            />
            <ErrorHandling />
            {!readOnly && (
                <Fragment>
                    <Heading title={"Upload material"} level={5} />
                    <Card>
                        <FileUpload
                            onAddFile={uploadFile}
                            infoContent="Please select a valid .axf or .zip file for upload"
                            validExtensions=".zip,.axf"
                            progress={uploadProgress}
                            uploadFinished={uploadFinished}
                            disabled={fileValidationInProgress}
                            onReset={resetUpload}
                            isUploading={uploadLoading}
                            fileName={fileName}
                            error={errorOnUpload}
                        />
                        {formik.errors.fileUrl && (
                            <small className="text-center text-danger mt-2">
                                Upload Required
                            </small>
                        )}
                    </Card>
                </Fragment>
            )}
            <Heading title={"Material Details"} level={5} />
            <Card>
                {loading ? (
                    <LoadIndicator cols={2} rows={3} />
                ) : error ? (
                    <span className="text-danger">
                        Error while retrieving the data{" "}
                        <div>
                            <Btn className="mt-2" onClick={load}>
                                Try loading data again
                            </Btn>
                        </div>
                    </span>
                ) : (
                    <div>
                        <div className={style.detailsFormTabs}>
                            <Tabs
                                tabs={updateTabs}
                                onTabChange={({ id }) => setCurrentTab(id)}
                                activeTab={currentTab}
                            />
                        </div>
                        <DetailsForm
                            data={mapDataForUpdate(
                                data,
                                companyId,
                                skuId,
                                formik.values,
                            )}
                            onChange={entry =>
                                onChangeDetailsFormEntry(
                                    entry,
                                    formik,
                                    data,
                                    velvetNoiseMasksData,
                                    shouldValidateOnChange,
                                    setDirty,
                                )
                            }
                            settings={detailsFormSettings}
                        />
                    </div>
                )}
            </Card>
            {!readOnly && (
                <Comment
                    readOnly={readOnly}
                    comment={formik.values.comment}
                    onChange={value => {
                        formik.setFieldValue(
                            "comment",
                            value,
                            shouldValidateOnChange,
                        );
                        setDirty(true);
                    }}
                    error={formik.errors.comment}
                />
            )}
            {!readOnly && (
                <Fragment>
                    <div className={style.archiveSection}>
                        <Heading title={"Archive"} level={5} />
                        <Card>
                            This will remove the material from Material Overview
                            list. The operation is irreversible.
                            <div className={style.archiveSectionButton}>
                                <Button
                                    size={"sm"}
                                    color={"danger"}
                                    outline
                                    onClick={() => setShowModal(true)}
                                >
                                    Archive
                                </Button>
                            </div>
                        </Card>
                    </div>
                    <Modal
                        open={showModal}
                        onCancel={() => setShowModal(false)}
                        title={"confirmation"}
                        actions={[
                            {
                                type: "primary",
                                label: "Archive",
                                action: () => {
                                    setShowModal(false);
                                    archive().then(() =>
                                        navigate("/materials"),
                                    );
                                },
                            },
                            {
                                type: "secondary",
                                label: "Cancel",
                                action: () => setShowModal(false),
                            },
                        ]}
                        confirmation
                    >
                        Are you sure you want to archive this material?
                    </Modal>
                </Fragment>
            )}
        </Container>
    );
};

export default UpdateMaterial;
