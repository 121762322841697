//@flow
import { Modal, Radio } from "@brutextiles/web-component-library";
import type { Node } from "react";
import React, { useEffect, useState } from "react";

import style from "../../style.module.scss";

const priorities: { label: string, value: number }[] = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 25 },
    { label: "High", value: 50 },
    { label: "Urgent", value: 100 },
];

type AmendPriorityModalProps = {
    show?: boolean,
    irdsCount?: number,
    onCancel: () => void,
    onSubmit: (priority: { label: string, value: number }) => void,
};

const AmendPriorityModal = ({
    show,
    irdsCount = 0,
    onCancel: handleCancel,
    onSubmit: handleSubmit,
}: AmendPriorityModalProps): Node => {
    const [priority, setPriority] = useState<{
        label: string,
        value: number,
    } | void>();
    useEffect(() => {
        setPriority(undefined);
    }, [show]);
    return (
        <Modal
            size={"lg"}
            confirmation
            open={show}
            title={"Amend priority"}
            onCancel={handleCancel}
            actions={[
                {
                    type: priority ? "primary" : "secondary",
                    label: "Submit",
                    action: () => priority && handleSubmit(priority),
                    disabled: !priority,
                },
                {
                    type: "secondary",
                    label: "Cancel",
                    action: handleCancel,
                },
            ]}
        >
            <div>
                <p>
                    {irdsCount === 1
                        ? "Select a priority level for the selected item:"
                        : `Select a priority level for selected ${irdsCount} items:`}
                </p>
            </div>
            <div className={style.amendPriority}>
                {priorities.map(
                    (priority: { label: string, value: number }) => (
                        <Radio
                            id={priority.value}
                            key={priority.value}
                            name={"priority"}
                            label={priority.label}
                            onClick={() => setPriority(priority)}
                        />
                    ),
                )}
            </div>
        </Modal>
    );
};
export default AmendPriorityModal;
