// @flow

import {
    Button,
    Card,
    Datatable,
    DetailsForm,
    FileUpload,
    Heading,
    Spinner,
    Stepper,
    Tabs,
    useStepper,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Container } from "reactstrap";

import { getUploadStatus } from "../../api";
import { Comment, ErrorHandling } from "../../components";
import {
    useCreate,
    useSuggestions,
    useTableData,
    useUpload,
} from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import sharedStyle from "../../styles/shared.module.scss";
import styles from "./create-material.module.scss";
import {
    adjustmentSettings,
    createTabs,
    mainDetailsSettings,
    mapDataForCreate,
} from "./detail-settings";
import tableSettings from "./table-settings";

const onChangeDetailsFormEntry = (
    entry,
    setDirty,
    setExposureCorrectionFactor,
    setTransparent,
    setOverrideTransparency,
    setOverrideDisplacement,
    setDisplacementMultiplier,
    setUseVelvetShader,
    setNoiseMaskVersionId,
    setNoiseAddFactor,
    velvetNoiseMasksData,
) => {
    if (entry.key === "exposureCorrectionFactor") {
        setExposureCorrectionFactor(entry.value);
        setDirty(true);
    }

    if (entry.key === "transparent") {
        setTransparent(entry.value === "TRANSPARENT");
        setDirty(true);
    }
    if (entry.key === "overrideTransparency") {
        setOverrideTransparency(entry.value);
        setDirty(true);
    }

    if (entry.key === "overrideDisplacement") {
        setOverrideDisplacement(entry.value);
        setDirty(true);
    }

    if (entry.key === "displacementMultiplier") {
        setDisplacementMultiplier(entry.value);
        setDirty(true);
    }

    if (entry.key === "useVelvetShader") {
        setUseVelvetShader(entry.value);
        if (entry.value) {
            const defaultNoiseMask = velvetNoiseMasksData?.velvetNoiseMasks[0];
            setNoiseMaskVersionId(defaultNoiseMask?.versionId);
            setNoiseAddFactor(defaultNoiseMask?.noiseAddFactor);
        } else {
            setNoiseMaskVersionId(null);
            setNoiseAddFactor(null);
        }
        setDirty(true);
    }

    if (entry.key === "noiseMaskVersionId") {
        setNoiseMaskVersionId(entry.value);
        if (entry.value) {
            const velvetNoiseMask = velvetNoiseMasksData?.velvetNoiseMasks.find(
                noiseMask => noiseMask.versionId === entry.value,
            );
            setNoiseAddFactor(velvetNoiseMask?.noiseAddFactor);
        } else {
            setNoiseAddFactor(null);
        }
        setDirty(true);
    }
};

const CreateMaterial = (): Node => {
    const [currentTab, setCurrentTab] = useState(0);
    const [detailsFormSettings, setDetailsFormSettings] = useState(
        mainDetailsSettings(),
    );
    const apiBase = "dap-search-service/erpcolour";

    const [
        { tableData, tableDataLoading, page },
        changeSorting,
        setSearchFilters,
        search,
        ,
        ,
        changePageInfo,
        setFullTextSearchQuery,
    ] = useTableData(
        {
            size: tableSettings.itemsPerPage || 10,
            sortField: tableSettings.sortField,
        },
        apiBase,
    );

    const { setDirty } = useContext(FormContext);

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
    ] = useSuggestions(apiBase);

    const [{ steps, activeStep }, setActiveStep, setStepvalidity] = useStepper([
        {
            label: "Upload and link material",
            name: "step1",
            valid: false,
            active: true,
        },
        {
            label: "Material summary",
            name: "step2",
            active: false,
            valid: false,
        },
    ]);

    const [
        {
            progress: uploadProgress,
            done: uploadFinished,
            loading: uploadLoading,
            uploadData,
            errorOnUpload,
        },
        uploadFile,
        resetUpload,
    ] = useUpload("/ams-api/material");

    const [{ createLoading }, create] = useCreate({
        overviewUrl: "/materials",
    });

    const [fileValidationInProgress, setFileValidationInProgress] = useState();
    const [comment, setComment] = useState("Initial upload");
    const [exposureCorrectionFactor, setExposureCorrectionFactor] =
        useState(1.0);
    const [transparent, setTransparent] = useState(false);
    const [overrideTransparency, setOverrideTransparency] = useState(false);
    const [useVelvetShader, setUseVelvetShader] = useState(false);
    const [displacementMultiplier, setDisplacementMultiplier] = useState(25);
    const [overrideDisplacement, setOverrideDisplacement] = useState(false);
    const [sku, setSku] = useState();
    const [noiseMaskVersionId, setNoiseMaskVersionId] = useState();
    const [noiseAddFactor, setNoiseAddFactor] = useState();

    const [{ data: noiseMaskData }, getNoiseMaskData] = useAxios(
        {
            validateStatus: status =>
                (status >= 200 && status < 400) || status === 404,
        },
        { manual: true, useCache: false },
    );

    const [{ data: velvetNoiseMasksData }] = useAxios(
        `/ams-api/noisemask/velvet`,
        {
            useCache: false,
        },
    );

    useEffect(() => {
        if (sku) {
            getNoiseMaskData({
                url: `/ams-api/noisemask/${sku.companyId}/${sku.designId}/latest`,
            });
        }
    }, [sku]);

    const [uploadStatus, setUploadStatus] = useState();

    useEffect(() => {
        if (uploadData?.Location) {
            setFileValidationInProgress(true);
            getUploadStatus(
                "/ams-api/material/upload/status",
                {
                    s3Url: uploadData.Location,
                },
                setFileValidationInProgress,
                setUploadStatus,
            );
        }
    }, [uploadData]);

    useEffect(() => {
        if (uploadStatus?.status === "IN_PROGRESS") {
            setTimeout(
                () =>
                    getUploadStatus(
                        "/ams-api/material/upload/status",
                        {
                            s3Url: uploadData.Location,
                        },
                        setFileValidationInProgress,
                        setUploadStatus,
                    ),
                3000,
            );
        } else if (uploadStatus) {
            setFileValidationInProgress(false);
        }
    }, [uploadStatus]);

    useEffect(() => {
        if (activeStep === 0) {
            if (
                sku &&
                uploadData?.Location &&
                uploadStatus?.status === "VALID"
            ) {
                setStepvalidity(0, true);
            } else {
                setStepvalidity(0, false);
            }
        } else if (activeStep === 1) {
            if (
                (!exposureCorrectionFactor || exposureCorrectionFactor <= 0) &&
                useVelvetShader &&
                !noiseMaskVersionId
            ) {
                return setStepvalidity(1, false);
            }

            if (
                comment &&
                uploadData?.Location &&
                exposureCorrectionFactor > 0 &&
                (!useVelvetShader || (useVelvetShader && noiseMaskVersionId))
            ) {
                setStepvalidity(1, true);
            }
        }

        setDirty(!!sku || !!uploadData);
    }, [
        sku,
        comment,
        activeStep,
        uploadData,
        uploadStatus,
        exposureCorrectionFactor,
        noiseMaskVersionId,
    ]);

    const validateStep = () => {
        if (activeStep === 0) {
            if (!sku || !uploadData?.Location) {
                return setStepvalidity(0, false);
            }
            setActiveStep(1);
        } else if (activeStep === 1) {
            if (!exposureCorrectionFactor || exposureCorrectionFactor <= 0) {
                return setStepvalidity(1, false);
            }

            if (!comment || !uploadData?.Location) {
                return setStepvalidity(1, false);
            }

            create({
                url: `/ams-api/material/${sku?.companyId || ""}/${
                    sku?.skuId || ""
                }`,
                data: {
                    comment,
                    s3FileUrl: uploadData?.Location,
                    exposureCorrectionFactor: Number(exposureCorrectionFactor),
                    transparent,
                    overrideTransparency,
                    useVelvetShader,
                    overrideDisplacement,
                    displacementMultiplier: displacementMultiplier
                        ? displacementMultiplier / 100
                        : 0,
                    materialType:
                        uploadData?.Location.split(".").pop() === "zip"
                            ? "VIZOO"
                            : "AXF",
                    noiseMaskVersionId: noiseMaskVersionId,
                    noiseAddFactor: noiseAddFactor,
                },
            });
        }
    };

    useEffect(() => {
        if (currentTab === 0) {
            setDetailsFormSettings(mainDetailsSettings());
        }

        if (currentTab === 1) {
            setDetailsFormSettings(
                adjustmentSettings(
                    false,
                    false,
                    overrideTransparency,
                    overrideDisplacement,
                    undefined,
                    useVelvetShader,
                    velvetNoiseMasksData,
                ),
            );
        }
    }, [
        currentTab,
        overrideTransparency,
        overrideDisplacement,
        useVelvetShader,
        velvetNoiseMasksData,
    ]);

    const setFilters = (filters: Array<{ [string]: string }>): void => {
        setSearchFilters(filters);
        setSuggestionFilters(filters);
    };

    const actionButtons = (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/materials")}
            >
                Cancel
            </Button>
            {steps[0].active ? (
                <Button
                    onClick={validateStep}
                    disabled={!steps[0].valid || fileValidationInProgress}
                    arrow
                >
                    {fileValidationInProgress ? <Spinner size="sm" /> : "Next"}
                </Button>
            ) : (
                <Button onClick={validateStep} disabled={!steps[1].valid}>
                    {createLoading ? <Spinner size="sm" /> : "Create"}
                </Button>
            )}
        </Fragment>
    );

    return (
        <Container fluid className={sharedStyle.wizard}>
            <div className={sharedStyle.stepper}>
                <Stepper steps={steps} onStepChange={setActiveStep} />
            </div>
            <Heading title="Create Material" rightContent={actionButtons} />
            <ErrorHandling />
            <Heading title={"Upload material"} level={5} />
            <Card>
                <FileUpload
                    onAddFile={uploadFile}
                    infoContent={
                        (uploadStatus && uploadStatus.status === "VALID") ||
                        fileValidationInProgress ||
                        uploadLoading
                            ? ""
                            : "Please select a valid .axf or .zip file for upload"
                    }
                    validExtensions=".zip,.axf"
                    disabled={fileValidationInProgress}
                    progress={uploadProgress}
                    isUploading={uploadLoading}
                    uploadFinished={uploadFinished}
                    onReset={resetUpload}
                    error={errorOnUpload}
                />
                {!!uploadData && !uploadData?.Location && (
                    <small className="text-center text-danger mt-2">
                        Upload Required
                    </small>
                )}
            </Card>
            {steps[0].active ? (
                <Fragment>
                    <Heading title={"Link SKU"} level={5} />
                    <div className={styles.tableWrapper}>
                        <Datatable
                            settings={tableSettings}
                            data={tableData?.results || []}
                            dataIsLoading={tableDataLoading}
                            multiSort={true}
                            onMultiSortChange={changeSorting}
                            onPageInfoChange={changePageInfo}
                            searchResults={suggestions?.result}
                            onSearchChange={setFilterQuery}
                            onRefresh={search}
                            onFilterChange={setFilters}
                            searchIsLoading={suggestionsLoading}
                            onQuery={setFullTextSearchQuery}
                            totalResults={tableData?.totalResults}
                            disableRowCheck={row => !!row.linkedToAmsMaterial}
                            onRowSelect={setSku}
                            onSelectedItemsChange={items => {
                                if (!items || items.length === 0) {
                                    setSku();
                                }
                            }}
                            page={page}
                        />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <Heading title={"Material Details"} level={5} />
                    <Card>
                        <div className={styles.detailsFormTabs}>
                            <Tabs
                                tabs={createTabs}
                                onTabChange={({ id }) => setCurrentTab(id)}
                                activeTab={currentTab}
                            />
                        </div>
                        <DetailsForm
                            settings={detailsFormSettings}
                            onChange={entry =>
                                onChangeDetailsFormEntry(
                                    entry,
                                    setDirty,
                                    setExposureCorrectionFactor,
                                    setTransparent,
                                    setOverrideTransparency,
                                    setOverrideDisplacement,
                                    setDisplacementMultiplier,
                                    setUseVelvetShader,
                                    setNoiseMaskVersionId,
                                    setNoiseAddFactor,
                                    velvetNoiseMasksData,
                                )
                            }
                            data={mapDataForCreate(
                                sku,
                                transparent,
                                overrideTransparency,
                                noiseMaskData,
                                exposureCorrectionFactor,
                                useVelvetShader,
                                displacementMultiplier,
                                overrideDisplacement,
                                noiseMaskVersionId,
                                noiseAddFactor,
                            )}
                        />
                    </Card>
                    <Comment
                        comment={comment}
                        onChange={setComment}
                        error={
                            (!!(!steps[1].valid && !comment) &&
                                "This field is required") ||
                            ""
                        }
                    />
                </Fragment>
            )}
        </Container>
    );
};

export default CreateMaterial;
