import * as Yup from "yup";

export default function validationSchema(noiseMaskData) {
    return Yup.object({
        companyId: Yup.string().when([], {
            is: () => noiseMaskData?.noiseMaskType === "VELVET",
            then: Yup.string().required("Company field is required"),
            otherwise: Yup.string(),
        }),
        width: Yup.number()
            .required("Width must be a valid number")
            .integer("Width can't be a decimal value")
            .positive()
            .max(2147483647),
        height: Yup.number()
            .required("Height must be a valid number")
            .integer("Height can't be a decimal value")
            .positive()
            .max(2147483647),
        noiseAddFactor: Yup.number()
            .required("Noise add factor must be a valid number")
            .test(
                "range",
                "Noise add factor must be a number between 0 and 1",
                value => value >= 0 && value <= 1,
            ),
        noiseMaskName: Yup.string().when([], {
            is: () => noiseMaskData?.noiseMaskType === "VELVET",
            then: Yup.string().required(
                "Name is a required field for VELVET noise mask",
            ),
            otherwise: Yup.string(),
        }),
        comment: Yup.string().required("Comment is a required field"),
    });
}
