export default readOnly => {
    return [
        {
            label: "Company ID",
            key: "companyId",
            inputType: "text",
            disabled: true,
        },
        {
            label: "Noise Mask Type",
            key: "noiseMaskType",
            input: "text",
            disabled: true,
        },
        {
            label: "Design ID",
            key: "designId",
            inputType: "text",
            disabled: true,
        },
        {
            label: "Design",
            key: "design",
            inputType: "text",
            disabled: true,
        },
        {
            label: "Launch",
            key: "launch",
            inputType: "text",
            disabled: true,
        },
        {
            label: "Height",
            key: "height",
            inputType: "number",
            inputAddon: {
                type: "append",
                label: "cm",
            },
            default: null,
            required: true,
            disabled: readOnly,
        },
        {
            label: "Brand",
            key: "brand",
            inputType: "text",
            disabled: true,
        },
        {
            label: "Width",
            key: "width",
            inputType: "number",
            inputAddon: {
                type: "append",
                label: "cm",
            },
            default: null,
            required: true,
            disabled: readOnly,
        },
        {
            label: "Noise Add Factor",
            key: "noiseAddFactor",
            inputType: "number",
            required: true,
            default: 0.2,
            disabled: readOnly,
        },
    ];
};
